import React, { useEffect, useState } from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import { FloatingLabel, Form, Button } from 'react-bootstrap'
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css';
import 'bootstrap/js/src/modal.js';
import 'bootstrap/js/src/dropdown.js';
import 'bootstrap/js/src/tooltip.js';
import 'bootstrap/dist/css/bootstrap.css';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useLocation } from 'react-router-dom';
import { LoadToast, updateToast, validation } from '../../../CommonHooks/commenfunctions';
import { AddRigionsCititesCountries, Blogs, Faq, Faqcategory, GetRegionsCategories } from '../../../Axios/admin.axios';
import { isEmpty } from 'validator';
import { toast } from 'react-toastify';
import Select, { components } from 'react-select';

const FormView = () => {

    const States = [
        { label: "City", value: "City" },
        { label: "Country", value: "Country" }
    ]

    const initial = {
        state: "",
        action: "add",
        stateImage: "",
        flagImage:"",
        link: "",
        amount: 0,
        type: ""

        // Id:"",

    };


    const [formdata, setFormdata] = useState(initial);
    const [err, setErr] = useState({});
    const [loader, setloader] = useState(false);
    const [Content, setContent] = useState("content");
    const [validation, setValidation] = useState({});
    const [RegCategories, setRegCategories] = useState([]);
    const [SelectOptions, setSelectOptions] = useState([])

    const { push } = useHistory();
    const location = useLocation()
    console.log("locationssdd", location);
    let Details = location?.state?.data
    console.log("RegCategories", RegCategories, Details);
console.log("SSTATEDSsss",{ label: Details?.state, value: Details?.state });
    useEffect(() => {
        GetallRegCat()
      
    }, [])
    console.log("FormdataEditss", formdata);
    const GetallRegCat = async () => {
        let resp = await GetRegionsCategories()
        console.log("responses_city", resp);
        if (resp.status) {
            let Data = resp?.data[0]?.Categories.map((item, i) => ({ label: item, value: item }))
            console.log("DDATATSSSSS", Data);
            setRegCategories(Data)
            setSelectOptions(resp?.data)

        }
        else {
            console.log("error");
        }
    }
    const onchange = (e) => {
        const { id, value } = e.target
        if (id == 'stateImage') {
            let file = e.target.files[0];
            console.log("imagefiles", file);
            setFormdata({ ...formdata, ...{ [id]: file } })

        }
        else if(id == "flagImage"){
            let file = e.target.files[0];
            console.log("imagefiles", file);
            setFormdata({ ...formdata, ...{ [id]: file } })
        }
        else {
            setErr({})
            setFormdata({ ...formdata, ...{ [id]: value } });
        }


    };
    console.log("Formdatas123", formdata);

    const handleChange = async (e, id) => {
        console.log("Emainggg", e);
        try {
            const { value } = e
            console.log("idsfsdgdfg", id, value);
            setFormdata({ ...formdata, [id]: value })
        } catch (error) {
            console.log("errir", error);
        }
    }


    const validateForm = () => {
        const validationErrors = {};
        if (!formdata.link) validationErrors.link = "link is required";
        if (!formdata.amount) validationErrors.amount = "amount is required";
        if (!formdata.type) validationErrors.type = "type is required";

        if (!formdata.state) validationErrors.state = "state is required";

        setValidation(validationErrors);
        return validationErrors;
    };

    const handleSubmit = async () => {
        try {
            // const errors = validateForm();
            // console.log("errrorsss",errors);
            // if (!isEmpty(errors)) {
            //     toast.error("Fix all validation errors");
            //     return;
            // }
            // else{

            // }

            let payload = {
                // ObjId: formdata._id,
                type: formdata.type,
                amount: formdata?.amount,
                link: formdata?.link,
                stateImage: formdata?.stateImage,
                flagImage: formdata?.flagImage,

                state: formdata?.state,
                action: "add"
            };
            console.log("Formdatassss", payload);
            const editResponse = await AddRigionsCititesCountries(payload);
            if (editResponse?.status) {
                setFormdata(editResponse.data);
                toast.success(editResponse.message);
                push("/Regions-lists");
            } else {
                toast.error(editResponse.message);
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };




    return (
        <div id="dashboard" className='showsidebar blog'>
            <div id='sidebar'>
                <Sidebar />
            </div>
            <div className='headandcont'>
                <div className="adminheader"><AdminHeader /></div>
                <div className='cont'>
                    <h4 className='adminblue my-4'>Add Regions</h4>
                    <div className='admin-forms mb-4 '>
                        <div className='shadowbox p-4 br-10'>

                            <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                                <Select
                                    options={States}
                                    // isMulti
                                    closeMenuOnSelect={false} // Keep the dropdown open for multi-select
                                    hideSelectedOptions={false} // Show all options even if selected
                                    // components={{ Option: CustomOption }} // Use custom option rendering
                                    id="type"
                                    onChange={(e) => handleChange(e, "type")}
                                    value={States?.find(e => e?.value == States?.value)}
                                />
                            </div>
                            <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                                <Select
                                    options={RegCategories}
                                    // isMulti
                                    closeMenuOnSelect={false} // Keep the dropdown open for multi-select
                                    hideSelectedOptions={false} // Show all options even if selected
                                    // components={{ Option: CustomOption }} // Use custom option rendering
                                    id="state"
                                    onChange={(e) => handleChange(e, "state")}
                                    value={RegCategories?.find(e => e?.value == RegCategories?.value)}
                                />
                            </div>

                            {/* {
                                <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Heading"
                                        id="Heading"
                                        className="mb-3" >
                                        <Form.Control type="text" disabled={false}
                                            placeholder="name@example.com" value={formdata?.Heading} onChange={(e) => onchange(e)}
                                        />
                                    </FloatingLabel>
                                    {err?.Heading && (
                                        <p className="text-left err_msg_txt">{err?.Heading}</p>
                                    )}
                                </div>
                            } */}

                            <div className="d-sm-flex gap-4 flex-1 px-4 pt-3">

                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Amount"
                                    className="mb-3"
                                >

                                    <Form.Control
                                        type="number"
                                        id="amount"
                                        placeholder="name@example.com"
                                        value={formdata?.amount}
                                        onChange={(e) => onchange(e)}

                                    />
                                </FloatingLabel>
                                {err?.amount && (
                                    <p className="text-left err_msg_txt">{err?.amount}</p>
                                )}

                            </div>

                            <div className="d-sm-flex gap-4 flex-1 px-4 pt-3">

                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Link"
                                    className="mb-3"
                                >

                                    <Form.Control
                                        type="link"
                                        id="link"
                                        placeholder="name@example.com"
                                        value={formdata?.link}
                                        onChange={(e) => onchange(e)}

                                    />
                                </FloatingLabel>
                                {err?.link && (
                                    <p className="text-left err_msg_txt">{err?.link}</p>
                                )}

                            </div>
                            <div className='upload_img px-4 pt-3 mb-4'>

                                <div className="custom_uploadBtn">
                                    <input type="file" id="stateImage" onChange={(e) => onchange(e)} />
                                </div>
                                {formdata?.stateImage && (
                                    <div className='preview_img mt-2'>
                                        <img
                                            className="mb-2"
                                            src={window.URL.createObjectURL(formdata?.stateImage)}
                                            width={90}
                                        />
                                    </div>
                                )}
                            </div>
                            {err?.stateImage && (
                                <p className="text-left err_msg_txt">{err?.stateImage}</p>
                            )}

<div className='upload_img px-4 pt-3 mb-4'>

<div className="custom_uploadBtn">
    <input type="file" id="flagImage" onChange={(e) => onchange(e)} />
</div>
{formdata?.flagImage && (
    <div className='preview_img mt-2'>
        <img
            className="mb-2"
            src={window.URL.createObjectURL(formdata?.flagImage)}
            width={90}
        />
    </div>
)}
</div>
{err?.flagImage && (
<p className="text-left err_msg_txt">{err?.flagImage}</p>
)}
                            {/* <div className="px-4">
                                <h5 className="mb-3">Content *:</h5>

                                <ReactSummernote
                                    value={Content}
                                    options={{
                                        //   lang: 'ru-RU',
                                        height: 350,
                                        dialogsInBody: true,
                                        toolbar: [
                                            ["style", ["style"]],
                                            ["font", ["bold", "underline", "clear"]],
                                            ["fontname", ["fontname"]],
                                            ["para", ["ul", "ol", "paragraph"]],
                                            ["table", ["table"]],
                                            ["insert", ["link", "picture", "video"]],
                                            ["view", ["fullscreen", "codeview"]],
                                        ],
                                    }}
                                    onChange={(e) => {
                                        setContent(e);
                                    }}
                                />
                            </div>
                            {isEmpty(Content) && (
                                <p className="text-left err_msg_txt">{"Content is required"}</p>
                            )} */}


                            <div className='px-4 text-end mt-3 btn_view_oneline' >
                                <button className='btn btn-theme me-3' onClick={() => handleSubmit()}>Submit</button>
                                <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={() => { push("/Regions-lists") }}>Cancel</Button>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    )
}


export default FormView