import React, { useState, Fragment, useEffect } from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import DataTable from '@ashvin27/react-datatable';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router-dom';
import { AddRigionsCititesCountries, Blogs, Faq, GetRegions } from '../../../Axios/admin.axios';
import config from '../../../config/config'
import { DeleteModal } from '../../../CommonHooks/Deletemodel';
import profile from "../../../Assets/images/user.jpg"
import { toast } from 'react-toastify';
import { Nav } from 'react-bootstrap'

const TableView = () => {
    let Report = [
        { label: "City", value: "City" },
        { label: "Country", value: "Country" }
    ]
    const [blogs, setBlogs] = useState([]);
    const [Deletemodel, setDeletemodel] = useState(false);
    const [Record, setRecord] = useState({});
    const { push } = useHistory();

    const [selectTab, setSelectTab] = useState("All")
    console.log("selectTab", selectTab.toLowerCase());
    console.log("bfDBGdfbfgb", blogs);

    useEffect(() => {
        GetallBlogs()
        // deleteblogs()
    }, [selectTab])

    const GetallBlogs = async () => {
        let resp = await GetRegions({ action: selectTab?.toLowerCase() })
        console.log("Respppppppppppp", resp);
        setBlogs(resp?.data)
    }
    const deleteblogs = async (value) => {

        let resp = await AddRigionsCititesCountries(
            {
                ObjId: value?._id,
                action: "delete",
            }
        )
        if (resp.status) {
            toast.success(resp.message)
            GetallBlogs()
        }
        else {
            toast.error(resp.message)

        }
    }


    const columns = [
        {
            key: "sno",
            text: "Id",
            className: "name",
            align: "left",
            sortable: true,
            cell: (record, index) =>
                <div>{index + 1}
                </div>
        },
        // {
        //     key: "type",
        //     text: "Type",
        //     className: "Type",
        //     align: "left",
        //     sortable: true,
        //     cell: (data) => (
        //         data?.type
        //     )

        // },
        {
            key: "state",
            text: "state",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) => (
                data?.state
            )

        },
        {
            key: "Link",
            text: "Link",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) => (
                data?.link ? data?.link?.length > 30 ? data?.link?.slice(0, 30) + "..." : data?.link : "-"
            )

        },
        {
            key: "amount",
            text: "Amount",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) => (
                data?.amount
            )

        },
        {
            key: "Image",
            text: "Image",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) => (
                !data?.stateImage ?

                    <img src={profile} alt="img" className='table_img status' />
                    :
                    <img src={`${config.IMG_URL}/CititesCountries/${data?.stateImage}`} alt="img" className='table_img status' />

            )

        },
        // {
        //     key: "Status",
        //     text: "Status",
        //     className: "name",
        //     align: "left",
        //     sortable: true,
        //     cell: (data) => (<div>
        //         <button onClick={()=>Hideshowfun(data)} className={data?.Status == true ? 'blog_statusBtn deactive' : 'blog_statusBtn active'}>
        //               {data?.Status ? "Deactive":"Active"}
        //             </button>

        //         </div>
        //         )


        // },

        {
            key: "action",
            text: "Edit action",
            className: "action",
            width: 100,
            align: "left",
            sortable: true,
            cell: record => {
                return (
                    <Fragment>

                        <button
                            className="btn btn-primary btn-sm cmn-btn"
                            onClick={() => editRecord(record)}
                            style={{ marginRight: '5px' }}
                        >
                            <i className="fa fa-edit mr-0"></i>
                        </button>

                        <button
                            className="btn btn-primary btn-sm cmn-btn"
                            onClick={() => { setDeletemodel(true); deleteblogs(record) }}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-trash mr-0"></i>
                        </button>
                    </Fragment>
                );
            }
        }

    ]

    const configs = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Users",
        no_data_text: 'No user found!',
        button: {
            excel: true,
            print: true,
            csv: true
        },
        className: "table",
        language: {
            length_menu: "Show _MENU_",
            filter: "Filter Blogs",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "<<",
                previous: "<",
                next: ">",
                last: ">>"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };


    const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

    const editRecord = async (data) => {
        push("Regions-citycountry-edit", { data: data });
    }



    return (
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar />
            </div>
            <div className='headandcont'>
                <div className="adminheader"><AdminHeader /></div>
                <div className='cont'>
                    <h4 className='adminblue my-4'>Regions lists</h4>
                    <Nav variant="pills"
                        className="navCustom_tabs mb-4"
                        // activeKey={activeTab}
                        defaultActiveKey="All"
                    >
                        <Nav.Item className='mb-3 mb-sm-0'>
                            <Nav.Link
                                eventKey={"All"}
                                onClick={() => { setSelectTab("All") }}
                            ><span>{"All"}</span></Nav.Link>
                        </Nav.Item>
                        {
                            Report.map((item, index) => (
                                <>
                                    {console.log("itemds", item.value)}
                                    <Nav.Item className='mb-3 mb-sm-0' key={index}>
                                        <Nav.Link eventKey={`tab-${index}`}
                                            onClick={() => { setSelectTab((item?.value).toString()) }}
                                        ><span>{item?.label}</span></Nav.Link>
                                    </Nav.Item>
                                </>))
                        }

                    </Nav>
                    <div className='pl-4 text-end mt-3' >
                        <button className='btn btn-theme me-0' onClick={() => { push('/Regions-citycountry-add') }}>Add</button>
                    </div>


                    <div className='tablesec shadowbox px-3 mt-3 br-10 mb-5'>
                        {/* <h6 className='adminblue pt-4 me-2' ><span className='fa fa-book'></span> View </h6> */}
                        {/* <DataTable pagination columns={columns} records={data} config = {config} className={config.className}/> */}
                        <DataTable striped responsive
                            config={configs}
                            className={configs.className}
                            records={blogs}
                            columns={columns}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}


export default TableView