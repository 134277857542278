import React, { useState, useEffect } from "react";
import AdminHeader from "../AdminHeader";
import Sidebar from "../Sidebar";
import { FloatingLabel, Form, Button } from "react-bootstrap";

import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css";
// import 'react-summernote/lang/summernote-ru-RU';
import "bootstrap/js/src/modal.js";
import "bootstrap/js/src/dropdown.js";
import "bootstrap/js/src/tooltip.js";
import "bootstrap/dist/css/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';

import { LoadToast, isEmpty, updateToast, validation } from "../../../CommonHooks/commenfunctions";
import { Get_Cms } from "../../../Axios/admin.axios";
import config from "../../../config/config"
import { useHistory, useLocation } from 'react-router-dom'
const FormView = (props) => {
  const initial = {
    Name: "",
    Description: "",
    Content:"",
    Path: "edit",
    Id: "",
    image: [],
    offers: []
  };
  const { push } = useHistory();
  const location = useLocation()
  const [formdata, setFormdata] = useState(initial);
  const [err, setErr] = useState({});
  const [loader, setloader] = useState(false);
  const [Content, setContent] = useState(location?.state?.data?.Content);
  const [offerImages, setOfferImages] = useState([])
  const [loding, setloading] = useState(false)



  console.log("locationlocationlocation", formdata, location,Content
  );
  console.log("DSFddddddddddd", Content);
  useEffect(() => {

    setloading(true)

    setFormdata({
      ...formdata, ...{
        Id: location?.state?.data?._id,
        Name: location?.state?.data?.Type,
        Description: location?.state?.data?.Description,
        Type: location?.state?.data?.Type,
        image: location?.state?.data?.image,
        Content: location?.state?.data?.Content,
        offers: location?.state?.data?.offers,
        link: location?.state?.data?.link,



      }
    })
    // setOffercontent(formdata)
    setContent(location?.state?.data?.Content)

    // if (location?.state?.data?.Type == "offerstop") {
    //   setOffercontent(location?.state?.data?.offers ? location?.state?.data?.offers.map(({ image, ...rest }) => rest) : [])
    //   let Images = location?.state?.data?.image ? location?.state?.data?.image.map(val => val) : []
    //   console.log("IMDATEGTSSSSSS", Images);
    //   // setOfferImages(location?.state?.data?.image ? location?.state?.data?.image.map(val => val || "") : [])
    //   setOfferImages([...Images])
    // }
  }, [location]);



console.log("dfsdfsdef",Content);



  const onchange = (id, value, index, type) => {
    console.log("OONNNNCHNAgesss", id, value, index, type);
    // if (type == "offerstop") {
    //   const updatedOfferContent = [...offercontent];
    //   updatedOfferContent[index] = {
    //     ...updatedOfferContent[index],
    //     [id]: value
    //   };
    //   setOffercontent(updatedOfferContent);

    // }
    // else {
      setErr({})
      setFormdata({ ...formdata, ...{ [id]: value } });
    // }

  };










  console.log("RFTTTTTTTTTTTTTTTTTTT", formdata);
  const handlesubmit = async (type) => {
    console.log("handleTypessssssssss", type);
    // if (type == "offerstop") {
    //   setFormdata({ ...formdata, ...{ ["Description"]: value } });

    // }
    setloader(true)
    const id = LoadToast();
    let err = await validation(Object.keys(initial), formdata);
    console.log("Errororotjeor", err)
    if (Object.keys(err)?.length > 0) {
      updateToast(id, "fix");
      setErr(err);
      setloader(false)

      return;
    }
    if (isEmpty(Content)) {
      updateToast(id, "fix");
      setErr({ Content: "Content is required" });


    }
    setErr({});
    formdata.Content = Content

    formdata.image = offerImages

    let { status, msg, data } = await Get_Cms(formdata);
    console.log("respoinsesss", status, msg, data);
    updateToast(
      id,
      msg,
      status
    );

    if (status == 'success') {
      push("/cms");

    }

    else {
      setErr(err);
      setloader(false)


    }

  };



  const handlechange = async (e, type) => {
    console.log("TYpeessss", type);
    // e.preventDefault();
    const { id } = e.target;
    console.log("idid", id);

    if (id == "file") {
      var files = e.target.files
      console.log("files in cms", files)
      if (type == "Media" && files?.length > 4) {
        return toast.warning("You can only select up to 4 images")
      }

      let formData = { ...formdata, ...{ "image": [...files] } }
      setFormdata(formData)
      setOfferImages([...files])
      console.log("formdartass", formData);
    }
    else {
      return toast.warning("This is not file")

    }
  }

  // const handleImageChange = (e, index) => {
  //   const file = e.target.files[0];
  //   console.log("Selected file:", file);

  //   // Update offerImages array
  //   const updatedImages = [...offerImages];
  //   console.log("ERTGEGRTHg", updatedImages);
  //   if (file) {
  //     updatedImages[index] = file;
  //   } else {
  //     updatedImages[index] = null;
  //   }

  //   // let ImageData = []
  //   // updatedImages.push(updatedImages)
  //   setOfferImages(updatedImages);

  //   // const updatedOfferContent = [...offercontent];
  //   // if (file) {
  //   //   updatedOfferContent[index] = {
  //   //     ...updatedOfferContent[index],
  //   //     image: file
  //   //   };
  //   // } else {
  //   //   delete updatedOfferContent[index].image;
  //   // }
  //   // setOffercontent(updatedOfferContent);
  // };
  // console.log("ofeerrreImageeesx", offerImages)

  // const handleImageChange = (e, index) => {
  //   const files = e.target.files[0];
  //   console.log("Selected file:", files);

  //   // Update offerImages array dynamically
  //   const updatedImages = [...offerImages];
  //   updatedImages[index] = files;

  //   setOfferImages(updatedImages);
  //   const updatedOfferContent = [...offercontent];
  //   updatedOfferContent[index] = { 
  //     ...updatedOfferContent[index], 
  //     ["image"]: files 
  //   };
  //   setOffercontent(updatedOfferContent);
  // };

  // console.log("werrrrrrrrrrrrrrr", offerImages);


  // const addReferral = async (e) => {
  //   try {
  //     setOffercontent([...offercontent, {}])
  //     // console.log("reerereretfgsrd")
  //     // const updatedList = formdata;
  //     // if (!updatedList || updatedList == 0) {
  //     //   toast.error("Expiry day is required")
  //     // }
  //     // else {
  //     //   console.log("gthfytjyugkjyuiik", updatedList)
  //     //   setFormdata({ ...formdata, offers: [...formdata?.offers, updatedList] });
  //     //   // console.log("gthfytjyugkjyuiik123", updatedList, formdata)
  //     //   setFormdata(updatedList)

  //     // }
  //   } catch (error) {
  //     console.log("ereredit", error)

  //   }

  // };

  return (
    <div id="dashboard" className="showsidebar">
      <div id="sidebar">
        <Sidebar />
      </div>
      <div className="headandcont">
        <div className="adminheader">
          <AdminHeader />
        </div>
        <div className="cont">
          <h4 className="adminblue my-4">Edit CMS</h4>
          <div className="admin-forms mb-4 ">

            <div className="shadowbox p-4 br-10">
              {(formdata?.Type == "privacypolicy" || formdata?.Type == "footer" || formdata?.Type == "canellationpolicy" || formdata?.Type == "termsandconditions") ?
                <>
                  <div className="d-sm-flex gap-4 flex-1 px-4 pt-3">

                    <FloatingLabel
                      controlId="floatingInput"
                      label="Name"
                      className="mb-3"
                    >

                      <Form.Control
                        id="Name"
                        type="email"
                        placeholder="name@example.com"
                        value={formdata?.Name}
                        onChange={(e) => onchange('Name', e.target.value)}
                        disabled={true}

                      />
                    </FloatingLabel>
                    {err?.Name && (
                      <p className="text-left err_msg_txt">{err?.Name}</p>
                    )}

                    {/* <div className="col-sm-6 mb-4">
                  <FloatingLabel controlId="floatingSelect" label="Status *">
                    <Form.Select
                      aria-label="Floating label select example"
                      value={status}
                      onChange={(e) => setStatus(e?.target?.value)}
                    >
                      <option value="">Select Status</option>
                      <option>Active</option>
                      <option>Inactive</option>
                    </Form.Select>
                  </FloatingLabel>
                </div> */}
                  </div>
                  <div className="d-sm-flex gap-4 flex-1 px-4 pt-3">

                    <FloatingLabel
                      controlId="floatingInput"
                      label="Describtion"
                      className="mb-3"
                    >

                      <Form.Control
                        id="Description"
                        type="email"
                        placeholder="name@example.com"
                        value={formdata?.Description}
                        onChange={(e) => onchange('Description', e.target.value)}
                      />
                    </FloatingLabel>
                    {err?.Description && (
                      <p className="text-left err_msg_txt">{err?.Description}</p>
                    )}

                  </div>
                  <div
                    className="px-4"
                  // onDrop={() => console.log("")}
                  >
                    <h5 className="mb-3">Content *:</h5>

                    <ReactSummernote
                      id="Content"
                      value={Content}

                      options={{
                        height: 350,
                        dialogsInBody: true,
                        toolbar: [
                          [
                            "style",
                            ["style", "bold", "italic", "underline", "clear"],
                          ],
                          [
                            "font",
                            [
                              "strikethrough",
                              "superscript",
                              "subscript",
                              "bold",
                              "underline",
                              "clear",
                            ],
                          ],
                          ["fontname", ["fontname"]],
                          ["fontsize", ["fontsize"]],
                          ["table", ["table"]],
                          ["color", ["color"]],
                          ["para", ["ul", "ol", "paragraph"]],
                          ["insert", ["link", "picture", "video"]],
                          ["view", ["fullscreen", "codeview"]][
                          ("height", ["height"])
                          ],
                        ],
                      }}
                      onChange={(e) => setContent(e)}

                    />
                  </div>
                  {err?.Content && (
                    <p className="text-left err_msg_txt">{err?.Content}</p>
                  )
                  }
                </>
                : <>
                  {formdata?.Type == "Media" ? (<>
                    <div className="d-sm-flex gap-4 flex-1 px-4 pt-3">

                      <FloatingLabel
                        controlId="floatingInput"
                        label="Describtion"
                        className="mb-3"
                      >

                        <Form.Control
                          id="Description"
                          type="email"
                          placeholder="name@example.com"
                          value={formdata?.Description}
                          onChange={(e) => onchange('Description', e.target.value)}
                        />
                      </FloatingLabel>
                      {err?.Description && (
                        <p className="text-left err_msg_txt">{err?.Description}</p>
                      )}

                    </div>
                    <div>
                      <input
                        type="file"
                        id="file"
                        multiple
                        onChange={(e) => handlechange(e, formdata?.Type)}
                      />
                    </div>
                    {formdata?.image.map((item, index) => {
                      <div key={index}></div>
                      { console.log("dcfsdgdf", formdata?.image, item, formdata?.image) }

                      return (
                        <>
                          <div>
                            {/* <img 
                          src={formdata?.image?.length == 0 ? `${config.IMG_URL}/cmsimages/${item}` : window.URL.createObjectURL(
                            item
                          )} width={100} /> */}
                            <img
                              className='mt-3 objectimg'
                              src={item
                                ? typeof item == "object"
                                  ? window.URL.createObjectURL(item)
                                  : `${config.IMG_URL}/cmsimages/${item}`
                                : ""
                              }
                              width={100} />
                          </div>
                          {/* <div><input type="file" id="file" multiple onChange={(e) => handlechange(e)} /></div> */}
                        </>
                      )
                    })}


                  </>)
                    :

                    (formdata?.Type == "offersmiddle")
                      ?
                      (<>

                        <div className="d-sm-flex gap-4 flex-1 px-4 pt-3">

                          <FloatingLabel
                            controlId="floatingInput"
                            label="Name"
                            className="mb-3"
                          >

                            <Form.Control
                              id="Name"
                              type="email"
                              placeholder="name@example.com"
                              value={formdata?.Name}
                              onChange={(e) => onchange('Name', e.target.value)}
                              disabled={true}

                            />
                          </FloatingLabel>
                          {err?.Name && (
                            <p className="text-left err_msg_txt">{err?.Name}</p>
                          )}

                        </div>
                        <div className="d-sm-flex gap-4 flex-1 px-4 pt-3">

                          <FloatingLabel
                            controlId="floatingInput"
                            label="Describtion"
                            className="mb-3"
                          >

                            <Form.Control
                              id="Description"
                              type="email"
                              placeholder="name@example.com"
                              value={formdata?.Description}
                              onChange={(e) => onchange('Description', e.target.value)}
                            />
                          </FloatingLabel>
                          {err?.Description && (
                            <p className="text-left err_msg_txt">{err?.Description}</p>
                          )}

                        </div>
                        <div
                          className="px-4"
                        // onDrop={() => console.log("")}
                        >
                          <h5 className="mb-3">Content *:</h5>

                          <ReactSummernote
                            id="Content"
                            value={Content}

                            options={{
                              height: 350,
                              dialogsInBody: true,
                              toolbar: [
                                [
                                  "style",
                                  ["style", "bold", "italic", "underline", "clear"],
                                ],
                                [
                                  "font",
                                  [
                                    "strikethrough",
                                    "superscript",
                                    "subscript",
                                    "bold",
                                    "underline",
                                    "clear",
                                  ],
                                ],
                                ["fontname", ["fontname"]],
                                ["fontsize", ["fontsize"]],
                                ["table", ["table"]],
                                ["color", ["color"]],
                                ["para", ["ul", "ol", "paragraph"]],
                                ["insert", ["link", "picture", "video"]],
                                ["view", ["fullscreen", "codeview"]][
                                ("height", ["height"])
                                ],
                              ],
                            }}
                            onChange={(e) => setContent(e)}

                          />
                        </div>
                        {err?.Content && (
                          <p className="text-left err_msg_txt">{err?.Content}</p>
                        )
                        }
                      </>)
                      :

                      formdata?.Type == "banner" ? (<>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Name"
                          className="mb-3"
                        >

                          <Form.Control
                            id="Name"
                            type="email"
                            placeholder="name@example.com"
                            value={formdata?.Name}
                            onChange={(e) => onchange('Name', e.target.value)}
                            disabled={true}

                          />
                        </FloatingLabel>
                        {err?.Name && (
                          <p className="text-left err_msg_txt">{err?.Name}</p>
                        )}

                        <div className="d-sm-flex gap-4 flex-1 px-4 pt-3">

                          <FloatingLabel
                            controlId="floatingInput"
                            label="Describtion"
                            className="mb-3"
                          >

                            <Form.Control
                              id="Description"
                              type="email"
                              placeholder="name@example.com"
                              value={formdata?.Description}
                              onChange={(e) => onchange('Description', e.target.value)}
                            />
                          </FloatingLabel>
                          {err?.Description && (
                            <p className="text-left err_msg_txt">{err?.Description}</p>
                          )}

                        </div>
                        <div
                          className="px-4"
                        // onDrop={() => console.log("")}
                        >
                          <h5 className="mb-3">Content *:</h5>
                          {console.log("sfvsdvsfsfg",Content)}

                          <ReactSummernote
                            id="Content"
                            value={Content}

                            options={{
                              height: 350,
                              dialogsInBody: true,
                              toolbar: [
                                [
                                  "style",
                                  ["style", "bold", "italic", "underline", "clear"],
                                ],
                                [
                                  "font",
                                  [
                                    "strikethrough",
                                    "superscript",
                                    "subscript",
                                    "bold",
                                    "underline",
                                    "clear",
                                  ],
                                ],
                                ["fontname", ["fontname"]],
                                ["fontsize", ["fontsize"]],
                                ["table", ["table"]],
                                ["color", ["color"]],
                                ["para", ["ul", "ol", "paragraph"]],
                                ["insert", ["link", "picture", "video"]],
                                ["view", ["fullscreen", "codeview"]][
                                ("height", ["height"])
                                ],
                              ],
                            }}
                            onChange={(e) => setContent(e)}

                          />
                        </div>
                        {err?.Content && (
                          <p className="text-left err_msg_txt">{err?.Content}</p>
                        )
                        }
                        <div>
                          <input
                            type="file"
                            id="file"
                            multiple
                            onChange={(e) => handlechange(e)}
                          />
                        </div>
                        {formdata?.image.map((item, index) => {
                          <div key={index}></div>
                          { console.log("dcfsdgdf", formdata?.image, item, formdata?.image) }

                          return (
                            <>
                              <div>
                                {/* <img 
                                  src={formdata?.image?.length == 0 ? `${config.IMG_URL}/cmsimages/${item}` : window.URL.createObjectURL(
                                    item
                                  )} width={100} /> */}
                                <img
                                  className='mt-3 objectimg'
                                  src={item
                                    ? typeof item == "object"
                                      ? window.URL.createObjectURL(item)
                                      : `${config.IMG_URL}/cmsimages/${item}`
                                    : ""
                                  }
                                  width={100} />
                              </div>
                              {/* <div><input type="file" id="file" multiple onChange={(e) => handlechange(e)} /></div> */}
                            </>
                          )
                        })}


                      </>)
                        :
                        formdata?.Type == "offerstop" ?
                          (<>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Name"
                              className="mb-3"
                            >

                              <Form.Control
                                id="Name"
                                type="email"
                                placeholder="name@example.com"
                                value={formdata?.Name}
                                onChange={(e) => onchange('Name', e.target.value)}
                                disabled={true}

                              />
                            </FloatingLabel>
                            {err?.Name && (
                              <p className="text-left err_msg_txt">{err?.Name}</p>
                            )}

                            <div className="d-sm-flex gap-4 flex-1 px-4 pt-3">

                              <FloatingLabel
                                controlId="floatingInput"
                                label="Describtion"
                                className="mb-3"
                              >

                                <Form.Control
                                  id="Description"
                                  type="email"
                                  placeholder="name@example.com"
                                  value={formdata?.Description}
                                  onChange={(e) => onchange('Description', e.target.value)}
                                />
                              </FloatingLabel>
                              {err?.Description && (
                                <p className="text-left err_msg_txt">{err?.Description}</p>
                              )}

                            </div>


                            <div className="d-sm-flex gap-4 flex-1 px-4 pt-3">

                              <FloatingLabel
                                controlId="floatingInput"
                                label="Link"
                                className="mb-3"
                              >

                                <Form.Control
                                  id="link"
                                  type="email"
                                  placeholder="name@example.com"
                                  value={formdata?.link}
                                  onChange={(e) => onchange('link', e.target.value)}
                                />
                              </FloatingLabel>
                              {err?.link && (
                                <p className="text-left err_msg_txt">{err?.link}</p>
                              )}

                            </div>

                            <div
                              className="px-4"
                            // onDrop={() => console.log("")}
                            >
                              <h5 className="mb-3">Content *:</h5>

                              <ReactSummernote
                                id="Content"
                                value={Content}

                                options={{
                                  height: 350,
                                  dialogsInBody: true,
                                  toolbar: [
                                    [
                                      "style",
                                      ["style", "bold", "italic", "underline", "clear"],
                                    ],
                                    [
                                      "font",
                                      [
                                        "strikethrough",
                                        "superscript",
                                        "subscript",
                                        "bold",
                                        "underline",
                                        "clear",
                                      ],
                                    ],
                                    ["fontname", ["fontname"]],
                                    ["fontsize", ["fontsize"]],
                                    ["table", ["table"]],
                                    ["color", ["color"]],
                                    ["para", ["ul", "ol", "paragraph"]],
                                    ["insert", ["link", "picture", "video"]],
                                    ["view", ["fullscreen", "codeview"]][
                                    ("height", ["height"])
                                    ],
                                  ],
                                }}
                                onChange={(e) => setContent(e)}

                              />
                            </div>
                            {err?.Content && (
                              <p className="text-left err_msg_txt">{err?.Content}</p>
                            )
                            }
                            {/* <div>
                              <input
                                type="file"
                                id="file"
                                multiple
                                onChange={(e) => handlechange(e, formdata?.Type)}
                              />
                            </div> */}


                            {/* {
                              offercontent.map((val, i) => {
                                console.log("offiererrContnenertns", val, i);
                                return (
                                  <>
                                    <div>Promotions</div>

                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Describtion"
                                      className="mb-3"
                                    >

                                      <Form.Control
                                        id="Description"
                                        type="Description"
                                        placeholder="name@example.com"
                                        value={val?.Description}
                                        onChange={(e) => onchange('Description', e.target.value, i, formdata?.Type)}
                                      />
                                    </FloatingLabel>
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Content"
                                      className="mb-3"
                                    >

                                      <Form.Control
                                        id="Content"
                                        type="Content"
                                        placeholder="name@example.com"
                                        value={val?.Content}
                                        onChange={(e) => onchange('Content', e.target.value, i, formdata?.Type)}
                                      />
                                    </FloatingLabel>
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Link"
                                      className="mb-3"
                                    >

                                      <Form.Control
                                        id="link"
                                        type="link"
                                        placeholder="name@example.com"
                                        value={val?.link}
                                        onChange={(e) => onchange('link', e.target.value, i, formdata?.Type)}
                                      />
                                    </FloatingLabel>
                                    <div>
                                      <input
                                        type="file"
                                        id="file"

                                        onChange={(e) => handleImageChange(e, i)}
                                      />
                                    </div>

                                    <img
                                      className='mt-3 objectimg'
                                      src={offerImages[i]
                                        ? typeof offerImages[i] == "object"
                                          ? window.URL.createObjectURL(offerImages[i])
                                          : `${config.IMG_URL}/cmsimages/${offerImages[i]}`
                                        : ""
                                      }
                                      width={100} />
                                  </>
                                )
                              })
                            } */}



                            {/* <div className='col-md-2 btnsec'>
                              <button className='grayss' type='button' onClick={() => addReferral()}>Add</button>
                            </div> */}

                          </>)
                          : ""
                  }
                </>



              }

              <div className="px-4 text-end mt-3 btn_view_oneline">
                <button className="btn btn-theme me-3" onClick={() => handlesubmit(formdata?.Type)}>
                  Submit
                </button>
                <Button
                  variant="outline-dark"
                  className="f-12 fw-600 outlinebtn"
                  onClick={() => {
                    push("/cms");
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormView;

{/* <div className="col-sm-6 mb-4">
                <h6>Image :</h6>
                <div className="upphoto my-3 ml-6">
                  <Button
                    variant="link"
                    className="nounder text-dark me-2 mb-2"
                  >
                    <div className={imageview && "d-none"}>
                      <span className="fa fa-photo" />
                      {console.log("imagesss", API_URL + path + "/" + image)}
                      <p>Upload image</p>
                    </div>
                    <img
                      src={imageview ? imageview : API_URL + path + "/" + image}
                    />

                    <input
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      onChange={(e) => {
                        console.log("e", e);
                        if (e?.target?.files[0]) {
                          setImageview(
                            URL.createObjectURL(e?.target?.files[0])
                          );
                          setImage(e?.target?.files[0]);
                        }
                      }}
                    />
                  </Button>
                </div>
              </div> */}