

// export default App;

import React, { Suspense, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import {  Route, Switch } from "react-router-dom";


import Aos from "aos";
import "aos/dist/aos.css";

import AdminRoute from "./AdminRoute";
import Dashboard from "./Components/Admin/Dashboard";
import Adminlogin from "./Components/Admin/Login";
import Manageuser from "./Components/Admin/Table/manageuser";
import Manageadmin from "./Components/Admin/Table/manageadmin";
import Addfaq from "./Components/Admin/Form/Addfaq";
import Forgotpassword from "./Components/Admin/Forget";
import Forgetchangepassword from "./Components/Admin/Forgetpassword.js";
import Managecms from "./Components/Admin/Table/managecms";
import FaqCms from "./Components/Admin/Table/Faqcms";
import FaqcategoriesCms from "./Components/Admin/Table/Faqcategory";
import Blogs from "./Components/Admin/Table/Blogs.js";
import Editcms from "./Components/Admin/Form/Editcms";
// import Editcms from "./Components/Admin/Form/EditCms.js";

import Editfaq from "./Components/Admin/Form/Editfaq";
import Editfaqcat from "./Components/Admin/Form/Editfaqcategory";
import Addfaqcat from "./Components/Admin/Form/Addfaqcat"; 
import Addblogs from "./Components/Admin/Form/Addblog.js";
import Editblogs from "./Components/Admin/Form/Editblogs.js";

import "./Assets/css/styles.css";
import Activity from "./Components/Admin/Table/Activity";

import Cms from "./Components/Admin/Table/Cms";
import ChangePasswords from "./Components/Admin/ChangePassword.js";
import Hotels from "./Components/Admin/Table/Hotels";
import Flight from "./Components/Admin/Table/Flight";
import SubAdmin from "./Components/Admin/Table/SubAdmin";
import AddCms from "./Components/Admin/Form/AddCms";
import AddSubAdmin from "./Components/Admin/Form/AddSubAdmin";
import EditSubAdmin from "./Components/Admin/Form/EditSubAdmin";
import Commission from "./Components/Admin/Form/Commission";
import DashboardInner from "./Components/Admin/DashboardInner";
import ReviewsList from "./Components/Admin/Table/ReviewsList";
import Sociallinks from "./Components/Admin/Table/Sociallinks.js"
import EditSociallinks from "./Components/Admin/Form/Editsociallinks.js"
import AddSociallinks from "./Components/Admin/Form/Addsociallinks.js"
import Subscribers from "./Components/Admin/Table/Subscriber.js"
import SendMail from "./Components/Admin/Form/Sendmail.js"
import EmailTemplate from "./Components/Admin/Table/Emailtemplate.js"
import EditEmailTemplate from "./Components/Admin/Form/Editemailtemplate.js"
import OffersTop from "./Components/Admin/Table/OffersTop.js"
// import AddEmailTemplate from "./Components/Admin/Table/AddEmailtemplate.js"
import AddOffersTop from "./Components/Admin/Form/AddOfferstop.js"

import EditOffersTop from "./Components/Admin/Form/EditOffers.js"
import RegionsCategoryLists from "./Components/Admin/Table/RegionsCategoryLists.js"
import RegionsCategoryadd from "./Components/Admin/Form/AddRegionsCategory.js"
import RegionsCityCountryadd from "./Components/Admin/Form/AddRegionsCityCountry.js"
import RegionsCityCountryLists from "./Components/Admin/Table/RegionsCitycountryLists.js"
import RegionsCityCountryedit from "./Components/Admin/Form/EditRegionsCityCountry.js"

import toast, { Toaster } from "react-hot-toast";




function App() {
  const cookies = new Cookies();
  const[Cookiesemail,setCookies]=useState()

  const adminRoutes = [
    // { path: "/login", component: Adminlogin ,access:['admin','subadmin']},
    { path: "/", component: Dashboard ,access:['admin',"subadmin"]},
    { path: "/dashboard", component: Dashboard ,access:['admin','subadmin']},
    { path: "/dashboard/:name", component: DashboardInner ,access:['admin','subadmin']},
    { path: "/manageadmin", component: Manageadmin ,access:['admin','subadmin']},
    { path: "/hotels/:name", component: ReviewsList ,access:['admin','subadmin'] },
    { path: "/activity", component: Activity ,access:['admin','subadmin']},
    { path: "/flight", component: Flight ,access:['admin','subadmin']},
    { path: "/sub-admin", component: SubAdmin ,access:['admin','subadmin']},
    { path: "/add-subadmin", component: AddSubAdmin ,access:['admin','subadmin']},
    { path: "/edit-subadmin", component: EditSubAdmin ,access:['admin','subadmin']},
    { path: "/commission", component: Commission ,access:['admin','subadmin']},
    { path: "/user", component: Manageuser,access:['admin','subadmin'] },
    { path: "/manage-cms", component: Managecms ,access:['admin','subadmin']},
    { path: "/cms", component: Cms ,access:['admin','subadmin']},
    { path: "/faqcategory", component: FaqcategoriesCms ,access:['admin','subadmin']},
    { path: "/edit-faqcategory", component: Editfaqcat ,access:['admin','subadmin']},
    { path: "/add-faqcategory", component: Addfaqcat ,access:['admin','subadmin']},
    { path: "/faq", component: FaqCms ,access:['admin','subadmin']},
    { path: "/edit-faq", component: Editfaq ,access:['admin','subadmin']},
    { path: "/add-faq", component: Addfaq ,access:['admin','subadmin']},
    { path: "/blog", component: Blogs ,access:['admin','subadmin']},
    { path: "/edit-blog", component: Editblogs ,access:['admin','subadmin']},
    { path: "/add-blog", component: Addblogs ,access:['admin','subadmin']},
    { path: "/add-cms", component: AddCms ,access:['admin','subadmin']},
    { path: "/edit-cms", component: Editcms ,access:['admin','subadmin']},
    { path: "/change-pwd", component: ChangePasswords ,access:['admin','subadmin']},
    { path: "/hotels", component: Hotels ,access:['admin','subadmin']},
    { path: "/sociallinks", component: Sociallinks ,access:['admin','subadmin']},
    { path: "/edit-sociallinks", component: EditSociallinks ,access:['admin','subadmin']},
    { path: "/add-sociallinks", component: AddSociallinks ,access:['admin','subadmin']},
    { path: "/subscribers", component: Subscribers ,access:['admin','subadmin']},
    { path: "/sendmail", component: SendMail ,access:['admin','subadmin']},
    { path: "/emailtemplate", component: EmailTemplate ,access:['admin','subadmin']},
    { path: "/edit-emailtemplate", component:EditEmailTemplate ,access:['admin','subadmin']},
    { path: "/Offers", component:OffersTop ,access:['admin','subadmin']},
    { path: "/addOffersTop", component:AddOffersTop ,access:['admin','subadmin']},
    { path: "/editOffersTop", component:EditOffersTop ,access:['admin','subadmin']},
    
    { path: "/Regions-category-list", component:RegionsCategoryLists ,access:['admin','subadmin']},

    { path: "/Regions-category-add", component:RegionsCategoryadd ,access:['admin','subadmin']},
    { path: "/Regions-lists", component:RegionsCityCountryLists ,access:['admin','subadmin']},
    { path: "/Regions-citycountry-add", component:RegionsCityCountryadd ,access:['admin','subadmin']},
    { path: "/Regions-citycountry-edit", component:RegionsCityCountryedit ,access:['admin','subadmin']},



    // { path: "/add-emailtemplate", component:AddEmailTemplate ,access:['admin','subadmin']},



  ].map(({ path, component, name ,access}, key) => (
    
    <Route exact path={path} component={access.includes(cookies.get('admin-role'))?component:Dashboard} key={key} name={name} />
  ))


  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
    Aos.refresh();
  }, []);

  return (
    <div className="App">
      <div> 
       <Toaster />
      </div>
      <Switch>
      <Route exact path={"/login"} component={()=>Adminlogin({stateemail:setCookies})} />
        <Route exact path={"/forgot-password"} component={Forgotpassword} />
        <Route exact path={"/change-password"} component={Forgetchangepassword} />
        <AdminRoute
         
          email={Cookiesemail??cookies.get("admin-email")}
        >
          {adminRoutes}
        </AdminRoute>
      </Switch>
    </div>
  );
}

export default App;
