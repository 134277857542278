import React, { useState, Fragment, useEffect } from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import { FloatingLabel, Form, Button } from 'react-bootstrap'
// import DataTable from 'react-data-table-component';
import DataTable from '@ashvin27/react-datatable';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router-dom';
import { Faq } from '../../../Axios/admin.axios';
import { LoadToast, updateToast } from '../../../CommonHooks/commenfunctions';
import { DeleteModal } from '../../../CommonHooks/Deletemodel';


const TableView = () => {
    const [faq, setFaq] = useState([]);
    const { push } = useHistory();
    const [Deletemodel, setDeletemodel] = useState(false);
    const [Record, setRecord] = useState({});


    useEffect(() => {
        Getallfaq()
    }, [])

    const Getallfaq = async () => {
        let { status, data } = await Faq({ Type: "get" })
        setFaq(data)
    }
    const deletefaq = async (value) => {
        const id = LoadToast();
        let { status, data, msg } = await Faq(
            {
                Id: value?._id,
                Type: "delete",
            }
        )
        updateToast(id, msg, status);

        if (status == 'success') {
            Getallfaq()


        }
    }
    const Hideshow = async (value) => {
        const id = LoadToast();
        let { status, data, msg } = await Faq(
            {
                Id: value?._id,
                Type: "hideshow",
                Status: !value?.Status
            }
        )

        updateToast(id, msg, status);

        if (status == 'success') {
            Getallfaq()


        }
    }


    const columns = [
        {
            key: "sno",
            text: "Id",
            className: "name",
            align: "left",
            sortable: true,
            cell: (record, index) =>
                <div>{index + 1}
                </div>
        },
        {
            key: "Category",
            text: "Category",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "Question",
            text: "Question",
            className: "name",
            align: "left",
            sortable: true,

        },
        {
            key: "Answer",
            text: "Answer",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) => (
                <div dangerouslySetInnerHTML={{ __html: data?.Answer?.length > 300 ? data?.Answer?.slice(0, 300) + "..." : data?.Answer }} />
            )

        },
        {
            key: "HideShow",
            text: "Status",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) => (<div>
                <button onClick={() => Hideshow(data)} className={data?.Status == true ? 'blog_statusBtn deactive' : 'blog_statusBtn active'}>
                    {data?.Status ? "Deactive" : "Active"}
                </button>

            </div>
            )


        },

        {
            key: "action",
            text: "Edit action",
            className: "action",
            width: 100,
            align: "left",
            sortable: true,
            cell: record => {
                return (
                    <Fragment>

                        <button
                            className="btn btn-primary btn-sm cmn-btn"
                            onClick={() => editRecord(record)}
                            style={{ marginRight: '5px' }}
                        >
                            <i className="fa fa-edit mr-0"></i>
                        </button>

                        <button
                            className="btn btn-primary btn-sm cmn-btn"
                            onClick={() => { setDeletemodel(true); setRecord(record) }}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-trash mr-0"></i>
                        </button>
                    </Fragment>
                );
            }
        }

    ]

    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Users",
        no_data_text: 'No user found!',
        button: {
            excel: true,
            print: true,
            csv: true
        },
        className: "table",
        language: {
            length_menu: "Show _MENU_",
            filter: "Filter Faq",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "<<",
                previous: "<",
                next: ">",
                last: ">>"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };


    const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

    const editRecord = async (data) => {
        push("edit-faq", { data: data });
    }



    return (
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar />
            </div>
            <div className='headandcont'>
                <div className="adminheader"><AdminHeader /></div>
                <div className='cont'>
                    <h4 className='adminblue my-4'>Faq</h4>
                    <div className='pl-4 text-end mt-3' >
                        <button className='btn btn-theme me-0' onClick={() => { push('/add-faq') }}>Add</button>
                    </div>


                    <div className='tablesec shadowbox px-3 mt-3 br-10 mb-5'>
                        {/* <h6 className='adminblue pt-4 me-2' ><span className='fa fa-book'></span> View FAQ</h6> */}
                        {/* <DataTable pagination columns={columns} records={data} config = {config} className={config.className}/> */}
                        <DataTable striped responsive
                            config={config}
                            className={config.className}
                            records={faq}
                            columns={columns}
                        />
                    </div>
                </div>
            </div>

            {Deletemodel && Record &&
                <DeleteModal
                    show={Deletemodel}
                    handleClose={() => setDeletemodel(false)}
                    Deletfunction={() => deletefaq(Record)}
                    Name={'Faq'}

                />

            }

        </div>

    )
}


export default TableView