import React, { useState, Fragment, useEffect } from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import DataTable from '@ashvin27/react-datatable';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router-dom';
import { Blogs, Faq, GetRegionsCategories } from '../../../Axios/admin.axios';
import { LoadToast, Describtion_showing, updateToast } from '../../../CommonHooks/commenfunctions';
import config from '../../../config/config'
import { DeleteModal } from '../../../CommonHooks/Deletemodel';
import profile from "../../../Assets/images/user.jpg"


const TableView = () => {

   

    const [RegCategories, setRegCategories] = useState([]);
    const [Deletemodel, setDeletemodel] = useState(false);
    const [Record, setRecord] = useState({});


    const { push } = useHistory();

    useEffect(() => {
        GetallRegCat()
    }, [])

    const GetallRegCat = async () => {
        let resp = await GetRegionsCategories()
        console.log("responses", resp);
        if (resp.status) {
            setRegCategories(resp?.data)

        }
        else {
            console.log("error");
        }
    }


    const columns = [
        {
            key: "sno",
            text: "Id",
            className: "name",
            align: "left",
            sortable: true,
            cell: (record, index) =>
                <div>{index + 1}
                </div>
        },

        {
            key: "Heading",
            text: "Heading",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) => (
                data?.Heading
            )

        },
        {
            key: "Content",
            text: "Content",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) => (
                <div dangerouslySetInnerHTML={{ __html: Describtion_showing(data?.Content) }} />
            )

        },



        {
            key: "action",
            text: "Edit action",
            className: "action",
            width: 100,
            align: "left",
            sortable: true,
            cell: record => {
                return (
                    <Fragment>

                        <button
                            className="btn btn-primary btn-sm cmn-btn"
                            onClick={() => editRecord(record)}
                            style={{ marginRight: '5px' }}
                        >
                            <i className="fa fa-edit mr-0"></i>
                        </button>
                        {/* 
                        <button
                            className="btn btn-primary btn-sm cmn-btn"
                            onClick={() => {setDeletemodel(true);setRecord(record)}}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-trash mr-0"></i>
                        </button> */}
                    </Fragment>
                );
            }
        }

    ]

    const configs = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Users",
        no_data_text: 'No user found!',
        button: {
            excel: true,
            print: true,
            csv: true
        },
        className: "table",
        language: {
            length_menu: "Show _MENU_",
            filter: "Filter Blogs",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "<<",
                previous: "<",
                next: ">",
                last: ">>"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };


    const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

    const editRecord = async (data) => {
        push("Regions-category-add", { data: data });
    }



    return (
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar />
            </div>
            <div className='headandcont'>
                <div className="adminheader"><AdminHeader /></div>
                <div className='cont'>
                    <h4 className='adminblue my-4'>Regions Categories</h4>
                    {/* <div className='pl-4 text-end mt-3' >
                        <button className='btn btn-theme me-0' onClick={() => { push('/Regions-category-add') }}>Add</button>
                    </div> */}

                  
                    <div className='tablesec shadowbox px-3 mt-3 br-10 mb-5'>
                        {/* <h6 className='adminblue pt-4 me-2' ><span className='fa fa-book'></span> View </h6> */}
                        {/* <DataTable pagination columns={columns} records={data} config = {config} className={config.className}/> */}
                        <DataTable
                            striped responsive
                            config={configs}
                            className={configs.className}
                            records={RegCategories}
                            columns={columns}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}


export default TableView