import { toast } from 'react-toastify';
import config from '../config/config'
import CryptoJS, { AES, enc } from "react-native-crypto-js";
import axios from 'axios'



export const validation = (requirefield, data) => {


    //* data must be an object *//
    let err = {}
    requirefield.forEach(function (field) {
      if (data[field]) {
        if (field.toLowerCase() == "password" || field.toLowerCase() == "oldpassword" || field.toLowerCase() == "confirmpassword" || field.toLowerCase() == "newpassword") {
  
          if (!config.PASSWORD.test(data[field])) {
            err = { ...err, ...{ [field]: field + " must be contain alpha numeric" } }
          }
        }
        if (field.toLowerCase() == "email") {
          if (!config.EMAIL.test(data[field])) {
            err = { ...err, ...{ [field]: " Please Enter valid email...!" } }
          }
        }
        if (field.toLowerCase() == "otp") {
          if (!config.OTP.test(data[field])) {
            err = { ...err, ...{ [field]: field + " Enter  6 digit OTP" } }
          }
        }

  
  
  
      }
      else {
        
    err = { ...err, ...{ [field]: field + " is required" } }
  

      }
  
  
    });
    return err;
  
  }

  export const LoadToast = (data) => { return toast.loading(data ?? "Loading.....") }
  export const updateToast = (id, data, type) => {
  
    try {
      if (id) {
        if (data == "fix") {
          data = "Fix All Validation"
          type = "error"
        }
        if (type == "error") type = toast.TYPE.ERROR
        if (type == "success" || type == true) type = toast.TYPE.SUCCESS
        
        if (type == "info") type = toast.TYPE.INFO
  
        setTimeout(() => {
          return toast.update(id, { render: data, type: type, isLoading: false, autoClose: 1000 })
  
        }, 1000)
        // 
        // return toast.update(id, { render: data, type: type, autoClose: 1000 })
      }
      else {
        // 
        return toast[type](data, 1000)
      }
    }
    catch (e) {
      console.error("tpast update", e)
      return false
    }
  }
  export const isEmpty = (value) =>
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value)?.length === 0) ||
    (typeof value === "string" && value.trim()?.length === 0) ||
    (typeof value === "string" && value == "error") ||
    (typeof value === "string" && value === "0") ||
    (typeof value === "number" && value === 0) ||
    (Array.isArray(value) === true && value?.length === 0);
    export const AppenData = (data) => {

        var formdata = new FormData()
      
        var SendDta = Object.entries(data).map((item) => {
          console.log('Array.isArrayArray.isArray', Array.isArray(item[1]))
          if (Array.isArray(item[1])) {
            var come = item[1].map(data => {
              if (data?.type) {
                console.log('Array.isArrayArray.isArray data?.type', data?.type, )
                formdata.append(item[0], (data))
              }
              else {
                formdata.append(item[0], EncryptData(data))
              }
              return formdata
            })
            return come
          }
          else {
            if (item?.[1]?.type) {
              formdata.append(item[0], (item[1]))
            }
            else {
              formdata.append(item[0], EncryptData(item[1]))
            }
            return formdata
          }
        })
        return SendDta
      }

      export const EncryptData = (data) => {
        const encJson = CryptoJS.AES.encrypt(JSON.stringify(data), config.SECRET_KEY).toString();
        const encData = CryptoJS.enc.Base64.stringify(
          CryptoJS.enc.Utf8.parse(encJson)
        );
        return encData;
      }
      
      
      export const Decryptdata = ((data) => {
        if (isEmpty(data)) {
          return data
        }
        const decData = CryptoJS.enc.Base64.parse(data)?.toString(CryptoJS.enc.Utf8);
        const bytes = CryptoJS.AES.decrypt(decData, config.SECRET_KEY).toString(CryptoJS.enc.Utf8);
      
        return JSON.parse(bytes)
      })
      
      export const axiosFunc = (async (data) => {
        try {
      
          let Resp = await axios(data)
      
          if (Resp?.data) {
            Resp.data = Resp.data ? isEmpty(Decryptdata(Resp.data)) ? Resp.data : Decryptdata(Resp.data) : Resp.data
          }
          console.log("Resp.dataResp.data",Resp.data);
          setTimeout(() => {
            if(Resp?.data?.type =='authentication'){
              toast.error(Resp?.data?.msg)
              }
          }, 1000);
      
          return Resp.data
        }
        catch (e) {
      
          return false
        }
      })
      export const Name_showing = (item) => {
        if (item && item.toString().length > 20) {
          var slice_front = item.slice(0, 15);
          return slice_front + "....";
        } else return item;
      };
      export const Describtion_showing = (item) => {
        if (item && item.toString().length > 70) {
          var slice_front = item.slice(0, 40);
          return slice_front + "....";
        } else return item;
      }


      export const formatNumber = (num) => {
        if (num >= 1e12) {
          return (num / 1e12).toFixed(1) + 'T'; // Trillions
        } else if (num >= 1e9) {
          return (num / 1e9).toFixed(1) + 'B'; // Billions
        } else if (num >= 1e6) {
          return (num / 1e6).toFixed(1) + 'M'; // Millions
        } else if (num >= 1e3) {
          return (num / 1e3).toFixed(1) + 'K'; // Thousands
        }
        return num.toString();
      }
      export const DateTimeForm = (data) => {
        try {
          let { date, datealone, timealone, ampm, words } = data;
          if (datealone) {
            return `${
              words ? new Date(date).toLocaleString('default', { day: 'long' }) : MinuteHourFormat(new Date(date)?.getDate())
            }/${MinuteHourFormat(
              new Date(date)?.getMonth() + 1
            )}/${MinuteHourFormat(new Date(date)?.getFullYear())}`;
          } else if (timealone) {
            if (ampm) {
              return `${MinuteHourFormat(
                new Date(date)?.getHours() > 12
                  ? new Date(date)?.getHours() - 12
                  : new Date(date)?.getHours()
              )}:${MinuteHourFormat(new Date(date)?.getMinutes())} ${new Date(date)?.getHours() >= 12 ? "PM" : "AM"
                }`;
            } else {
              return `${MinuteHourFormat(
                new Date(date)?.getHours()
              )}:${MinuteHourFormat(new Date(date)?.getMinutes())} `;
            }
          } else if (ampm) {
            return `${MinuteHourFormat(new Date(date)?.getDate())}/${MinuteHourFormat(
              new Date(date)?.getMonth() + 1
            )}/${MinuteHourFormat(new Date(date)?.getFullYear())}, ${MinuteHourFormat(
              new Date(date)?.getHours() > 12
                ? new Date(date)?.getHours() - 12
                : new Date(date)?.getHours()
            )}:${MinuteHourFormat(new Date(date)?.getMinutes())} ${new Date(date)?.getHours() >= 12 ? "PM" : "AM"
              } `;
          }
          else if(words){
      
              return `
              ${MinuteHourFormat(new Date(date)?.getDate())}
              ${new Date(date).toLocaleString('default', { weekday: 'long' })}
              ${new Date(date).toLocaleString('default', { month: 'long' })}
              ${MinuteHourFormat(new Date(date)?.getFullYear())} 
         `;
      //    ${MinuteHourFormat(
      //     new Date(date)?.getHours() > 12
      //       ? new Date(date)?.getHours() - 12
      //       : new Date(date)?.getHours()
      //   )}:
      //  ${MinuteHourFormat(new Date(date)?.getMinutes())} ${new Date(date)?.getHours() >= 12 ? "PM" : "AM"
          // } 
          }
          return `${MinuteHourFormat(new Date(date)?.getDate())}:${MinuteHourFormat(
            new Date(date)?.getMonth() + 1
          )}:${new Date(date)?.getFullYear()},${MinuteHourFormat(
            new Date(date)?.getHours()
          )}:${MinuteHourFormat(new Date(date)?.getMinutes())} `;
        } catch (err) {
          console.log("DateTimeFormDateTimeFormP_err", err);
          return "No Date";
        }
      };
      
      
      const MinuteHourFormat = (data) => {
        return ((Number(isEmpty(data) ? 0 : data ) < 10 ? '0' : '')+data)
      }