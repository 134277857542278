import { AppenData, axiosFunc } from '../CommonHooks/commenfunctions'
import config from '../config/config'

/**Admin Login */
export const Login = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/admin/login`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }



}
/** Send Otp for forgetpassword*/
export const SendOtp = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/admin/sendotp`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }



}
/** Verify Otp for forgetpassword*/
export const VerifyOtp = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/admin/verifyotp`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }



}
/** Reset  for forgetpassword*/

export const ChangePassword = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/admin/changepassword`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }



}
/** Get all cms*/
export const Get_Cms = async (data) => {
    console.log("GetCmsData_12", data);
    try {
        let formdata = AppenData(data);

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/cms/geteditcms`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}
/** Get all faq*/

export const Faq = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/cms/faq`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}
/** Get all faqcategory*/
export const Faqcategory = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/cms/faqcategory`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}
/** Get all Blogs*/

export const Blogs = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/cms/blog`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}
/** Get all Userdetails*/

export const Userdetails = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/admin/user`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}

/** Get all Userdetails*/

export const Booking_HotelDetails = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/hotels/bookingdetails`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}

/** Get all Userdetails*/

export const AllHotelDetails = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/hotels/allbookinghotels`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}


/**Get Userreviews for dashboard*/
export const UserReviews = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/hotels/userreviews`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}

/**Get Userreviews for dashboard*/
export const AdminDetails = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/admin/admindetials`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}

/**Get Userreviews for dashboard*/
export const Addadmin = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/admin/adminadd`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}

/**Get Commissions */

export const Commissions = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/admin/commissions`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}

/**Get Hotel Reviews */

export const AllReviwes = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/admin/allreviwes`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}
/**Get Sociallink */

export const Get_Sociallinks = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/cms/getsocillinks`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}

export const GetSubscribers = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/admin/subscribers`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}


export const Selectdatachange = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/admin/selectchange`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}

// send MAil to subscribers

export const SendMailsToSubscribers = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/admin/sendmail`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}

// Change email templates
export const Emailtemplates = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/admin/emailtemplates`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}

//offers add
export const OffersAddEdit = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/cms/AddEditOffers`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}

//Add Regions Category
export const Addregionscategory = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/admin/addRegionsCategories`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'application/json',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}

export const GetRegionsCategories = async () => {
    try {
        // let formdata = AppenData(data)

        let SendData = {
            'method': 'GET',
            'url': `${config.Back_Url}/admin/admin/getRegionsCategories`,
            // 'data': formdata[0],
            'headers': {
                'Content-Type': 'application/json',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}


//Regions add cities and countries
export const AddRigionsCititesCountries = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/admin/addRigionsCititesCountries`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)

        return Resp


    } catch (error) {
        return false

    }
}

export const GetRegions = async (data) => {
    try {
        let formdata = AppenData(data)

        let SendData = {
            'method': 'POST',
            'url': `${config.Back_Url}/admin/admin/getRegions`,
            'data': formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }
        let Resp = await axiosFunc(SendData)
        console.log("REponsesDATa", Resp);
        return Resp


    } catch (error) {
        return false

    }
}