import React, { useState, useEffect } from "react";
import AdminHeader from "../AdminHeader";
import Sidebar from "../Sidebar";
import { FloatingLabel, Form, Button } from "react-bootstrap";

import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css";
// import 'react-summernote/lang/summernote-ru-RU';
import "bootstrap/js/src/modal.js";
import "bootstrap/js/src/dropdown.js";
import "bootstrap/js/src/tooltip.js";
import "bootstrap/dist/css/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";

import {
  LoadToast,
  isEmpty,
  updateToast,
  validation,
} from "../../../CommonHooks/commenfunctions";
import {
  Addregionscategory,
  GetRegionsCategories,
  Get_Cms,
} from "../../../Axios/admin.axios";
import config from "../../../config/config";
import { useHistory, useLocation } from "react-router-dom";
const AddRegionsCategory = (props) => {
  const [formdata, setFormdata] = useState({});
  const [err, setErr] = useState({});
  const [loader, setloader] = useState(false);
  const [value, setValue] = useState("");
  const [validation, setValidation] = useState({});
  const [category, setCategory] = useState("");

  const { push } = useHistory();
  const location = useLocation();
  console.log("locationlocationlocation", formdata, location);

  useEffect(() => {
    const Details = location.state.data;
    console.log("detalosssss", Details);
    setFormdata(Details);
    setValue(Details?.Content);
  }, [location.state]);

  console.log("RFTTTTTTTTTTTTTTTTTTT", formdata);
  const validateForm = () => {
    const validationErrors = {};
    if (!formdata.Heading) validationErrors.Heading = "Heading is required";
    if (!value || value == "<p><br></p>")
      validationErrors.Content = "Content is required";
    setValidation(validationErrors);
    return validationErrors;
  };

  const handleChange = (content) => {
    console.log("ContensDatas", content);
    setValue(content);
  };

  const handleChangeForm = (e) => {
    const { id, value } = e.target;
    console.log("idssssss", id, value);

    setFormdata({ ...formdata, [id]: value });
  };

  const handleSubmit = async () => {
    try {
      const errors = validateForm();
      if (!isEmpty(errors)) {
        toast.error("Fix all validation errors");
        return;
      }

      const payload = {
        regionObjId: formdata._id,
        Type: formdata.Type,
        Heading: formdata.Heading,
        Content: value,
        Categories: JSON.stringify(formdata.Categories),
      };
      console.log("Formdatassss", payload);
      const editResponse = await Addregionscategory(payload);
      if (editResponse?.status) {
        setFormdata(editResponse.data);
        toast.success(editResponse.message);
        push("/Regions-category-list");
      } else {
        toast.error(editResponse.msg);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const addReferral = async (e) => {
    try {
      console.log("reerereretfgsrd");
      const updatedList = category;
      if (!updatedList || updatedList == 0) {
        toast.error("Category is required");
      } else {
        console.log("gthfytjyugkjyuiik", updatedList, formdata);
        setFormdata({
          ...formdata,
          Categories: [...formdata?.Categories, updatedList],
        });
        console.log("gthfytjyugkjyuiik123", updatedList, formdata);
        setCategory("");
      }
    } catch (error) {
      console.log("ereredit", error);
    }
  };

  const handleEdit = async (e, index) => {
    try {
      const { id, value } = e.target;
      console.log("valuevalue", value);
      let edit = [...formdata?.Categories];
      edit[index] = value;
      setFormdata({ ...formdata, Categories: edit });
    } catch (error) {
      console.log("ereredit", error);
    }
  };
  const removeReferral = async (e, index) => {
    try {
      console.log("dgfhdfghfghg", e, index);
      const updatedList = [...formdata.Categories];
      updatedList.splice(index, 1);
      setFormdata({ ...formdata, Categories: updatedList });
      console.log("fdgtgfh", updatedList);
    } catch (error) {
      console.log("ereredit", error);
    }
  };

  return (
    <div id="dashboard" className="showsidebar">
      <div id="sidebar">
        <Sidebar />
      </div>
      <div className="headandcont">
        <div className="adminheader">
          <AdminHeader />
        </div>
        <div className="cont">
          <h4 className="adminblue my-4">Edit Regions</h4>
          <div className="admin-forms mb-4 ">
            <div className="shadowbox p-4 br-10">
              <>
                <div className="d-sm-flex gap-4 flex-1 px-4 pt-3">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Type"
                    className="mb-3"
                  >
                    <Form.Control
                      id="Name"
                      type="email"
                      placeholder="name@example.com"
                      value={formdata?.Type}
                      onChange={(e) => onchange("Name", e.target.value)}
                      disabled={true}
                    />
                  </FloatingLabel>
                  {err?.Name && (
                    <p className="text-left err_msg_txt">{err?.Name}</p>
                  )}

                  {/* <div className="col-sm-6 mb-4">
                  <FloatingLabel controlId="floatingSelect" label="Status *">
                    <Form.Select
                      aria-label="Floating label select example"
                      value={status}
                      onChange={(e) => setStatus(e?.target?.value)}
                    >
                      <option value="">Select Status</option>
                      <option>Active</option>
                      <option>Inactive</option>
                    </Form.Select>
                  </FloatingLabel>
                </div> */}
                </div>
                <div className="d-sm-flex gap-4 flex-1 px-4 pt-3">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Heading"
                    className="mb-3"
                  >
                    <Form.Control
                      id="Heading"
                      type="email"
                      placeholder="name@example.com"
                      value={formdata?.Heading}
                      onChange={(e) => handleChangeForm(e)}
                    />
                  </FloatingLabel>
                  {err?.Heading && (
                    <p className="text-left err_msg_txt">{err?.Heading}</p>
                  )}
                </div>
                <div
                  className="px-4"
                  // onDrop={() => console.log("")}
                >
                  <h5 className="mb-3">Content *:</h5>

                  <ReactSummernote
                    id="Content"
                    value={formdata?.Content}
                    options={{
                      height: 350,
                      dialogsInBody: true,
                      toolbar: [
                        [
                          "style",
                          ["style", "bold", "italic", "underline", "clear"],
                        ],
                        [
                          "font",
                          [
                            "strikethrough",
                            "superscript",
                            "subscript",
                            "bold",
                            "underline",
                            "clear",
                          ],
                        ],
                        ["fontname", ["fontname"]],
                        ["fontsize", ["fontsize"]],
                        ["table", ["table"]],
                        ["color", ["color"]],
                        ["para", ["ul", "ol", "paragraph"]],
                        ["insert", ["link", "picture", "video"]],
                        ["view", ["fullscreen", "codeview"]][
                          ("height", ["height"])
                        ],
                      ],
                    }}
                    onChange={handleChange}
                  />
                </div>
                {err?.Content && (
                  <p className="text-left err_msg_txt">{err?.Content}</p>
                )}
              </>

              <div className="card-body">
                <h4 className="card-title mb-3">Categories</h4>

                <div className="row mb-3">
                  <div className="col-md-8 col-lg-7 col-xl-6 col-xxl-5">
                    <Form>
                      <div className="d-flex gap-3">
                        <Form.Group
                          className="w-100"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Category</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter the category"
                            value={category}
                            id="Categories"
                            onChange={(e) => {
                              setCategory(e.target.value);
                            }}
                          />
                        </Form.Group>
                        <div className="d-flex align-items-end">
                          <button
                            className="btn btn-theme"
                            type="button"
                            onClick={() => addReferral()}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>

                {formdata?.Categories?.length > 0 &&
                  formdata?.Categories?.map((e, index) => (
                    <div key={index}>
                      <div className="row">
                        <div className="col-md-8 col-lg-7 col-xl-6 col-xxl-5 mb-3">
                          <Form>
                            <div className="d-flex gap-3">
                              <Form.Group
                                className="w-100"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Label>Category</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter the category"
                                  value={e}
                                  id="Categories"
                                  onChange={(e) => {
                                    handleEdit(e, index);
                                  }}
                                />
                              </Form.Group>
                              <div className="d-flex align-items-end">
                                <button
                                  className="btn btn-theme"
                                  type="button"
                                  onClick={() => removeReferral(e, index)}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="px-4 text-end mt-3 btn_view_oneline">
                <button
                  className="btn btn-theme me-3"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </button>
                <Button
                  variant="outline-dark"
                  className="f-12 fw-600 outlinebtn"
                  onClick={() => {
                    push("/Regions-category-list");
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRegionsCategory;
