import React,{useEffect, useState} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; 
import 'bootstrap/js/src/modal.js';
import 'bootstrap/js/src/dropdown.js';
import 'bootstrap/js/src/tooltip.js';
import 'bootstrap/dist/css/bootstrap.css';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useLocation } from 'react-router-dom';
import { LoadToast, updateToast, validation } from '../../../CommonHooks/commenfunctions';
import { Faq, Faqcategory } from '../../../Axios/admin.axios';
import { isEmpty } from 'validator';

const FormView = () =>{
    
    const initial = {
    Question: "",
    Category:"",
    Type:"edit",
    Id:"",

  };
  const [formdata, setFormdata] = useState(initial);
  const [err, setErr] = useState({});
  const [loader, setloader] = useState(false);
  const [Answer, setAnswer] = useState("");

  const { push } = useHistory();
  const location =useLocation()

  useEffect(() => {
    setFormdata({...formdata,...{
      Id:location?.state?.data?._id,
      Question:location?.state?.data?.Question,
      Category:location?.state?.data?.Category
    }})
    setAnswer(location?.state?.data?.Answer)
  }, []);
console.log("Anserwer",Answer);

  const onchange = (id,value) => {
    setErr({})
    setFormdata({ ...formdata, ...{ [id]: value } });
  };

  const handlesubmit = async () => {
    setloader(true)
    const id = LoadToast();
    let err = await validation(Object.keys(initial), formdata);
    if (Object.keys(err)?.length > 0) {
      updateToast(id, "fix");
      setErr(err);
      setloader(false)

      return;
    }
    if (isEmpty(Answer)) {
        updateToast(id, "fix");
        setErr({Answer:"Answer is required"});
        setloader(false)
  
        return;
      }

    setErr({});

    formdata.Answer=Answer

    let { status,msg,data } = await Faq(formdata);

    updateToast(
      id,
      msg,
      status
    );

    if (status=='success') {
        push("/faq");

       } 
  
       else{
        setErr(err);
        setloader(false)


       }

  };




    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Edit FAQ</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>

                        

                        { 
                        <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Question"
                                className="mb-3" >
                            <Form.Control type="text" disabled={false}
placeholder="name@example.com" value={formdata?.Question} onChange={(e)=>onchange('Question',e.target.value)}
 />
                            </FloatingLabel>
                            {err?.Question && (
                      <p className="text-left err_msg_txt">{err?.Question}</p>
                    )}
                        </div>
                        }

             <div className="d-sm-flex gap-4 flex-1 px-4 pt-3">

                <FloatingLabel
                  controlId="floatingInput"
                  label="Category"
                  className="mb-3"
                >

                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    value={formdata?.Category}
                    onChange={(e)=>onchange('Category',e.target.value)}
                    disabled={true}
                  />
                </FloatingLabel>
                {err?.Category && (
                      <p className="text-left err_msg_txt">{err?.Category}</p>
                    )}

              </div>
              <div className="px-4">
                <h5 className="mb-3">Answer *:</h5>

                <ReactSummernote
                  value={Answer}
                  options={{
                    //   lang: 'ru-RU',
                    height: 350,
                    dialogsInBody: true,
                    toolbar: [
                      ["style", ["style"]],
                      ["font", ["bold", "underline", "clear"]],
                      ["fontname", ["fontname"]],
                      ["para", ["ul", "ol", "paragraph"]],
                      ["table", ["table"]],
                      ["insert", ["link", "picture", "video"]],
                      ["view", ["fullscreen", "codeview"]],
                    ],
                  }}
                  onChange={(e) => {
                    setAnswer(e);
                  }}
                />
              </div>
                      
                        <div className='px-4 text-end mt-3 btn_view_oneline' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={()=>{push("/faq")}}>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}


export default FormView