import React, { useState, Fragment, useEffect } from "react";
import AdminHeader from "../AdminHeader";
import Sidebar from "../Sidebar";
import DataTable from "@ashvin27/react-datatable";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";
import { Blogs, Faq, OffersAddEdit } from "../../../Axios/admin.axios";
import {
  LoadToast,
  Describtion_showing,
  updateToast,
} from "../../../CommonHooks/commenfunctions";
import config from "../../../config/config";
import { DeleteModal } from "../../../CommonHooks/Deletemodel";
import profile from "../../../Assets/images/user.jpg";
import Select, { components } from "react-select";
import { Nav } from "react-bootstrap";

const TableView = () => {
  let Report = [
    { label: "Offer Top", value: "Offer Top" },
    { label: "Offer Middle", value: "Offer Middle" },
  ];

  const [blogs, setBlogs] = useState([]);
  const [Deletemodel, setDeletemodel] = useState(false);
  const [Record, setRecord] = useState({});
  const [selectTab, setSelectTab] = useState("All");
  console.log("selectTab", selectTab.toLowerCase());
  const { push } = useHistory();

  useEffect(() => {
    GetallBlogs();
    // deleteblogs()
  }, [selectTab]);

  const GetallBlogs = async () => {
    let { status, data } = await OffersAddEdit({
      action: selectTab.toLowerCase(),
    });
    setBlogs(data);
  };
  const deleteblogs = async (value) => {
    const id = LoadToast();
    let payload = {
      Id: value?._id,
      action: "delete",
    };
    let resp = await OffersAddEdit(payload);
    if (resp.status) {
      updateToast(id, resp?.message, resp?.status);
      GetallBlogs();
    } else {
      updateToast(id, resp?.message, resp?.status);
    }
  };

  const Hideshowfun = async (value) => {
    const id = LoadToast();
    let payload = {
      Id: value?._id,
      action: "hideshow",
      Status: !value?.Status,
    };
    let resp = await OffersAddEdit(payload);
    if (resp.status) {
      updateToast(id, resp?.message, resp?.status);
      GetallBlogs();
    } else {
      updateToast(id, resp?.message, resp?.status);
    }
  };

  const columns = [
    {
      key: "sno",
      text: "Id",
      className: "name",
      align: "left",
      sortable: true,
      cell: (record, index) => <div>{index + 1}</div>,
    },

    {
      key: "Heading",
      text: "Heading",
      className: "name",
      align: "left",
      sortable: true,
      cell: (data) => data?.Heading,
    },
    {
      key: "Content",
      text: "Content",
      className: "name",
      align: "left",
      sortable: true,
      cell: (data) => (
        <div
          dangerouslySetInnerHTML={{
            __html: Describtion_showing(data?.Content),
          }}
        />
      ),
    },
    {
      key: "Link",
      text: "Link",
      className: "name",
      align: "left",
      sortable: true,
      cell: (data) => (data?.link ? data?.link?.length > 30 ? data?.link?.slice(0,30) + "..." : data?.link : "-"),
    },
    {
      key: "Image",
      text: "image",
      className: "name",
      align: "left",
      sortable: true,
      cell: (data) =>
        !data?.image ? (
          <img src={profile} alt="img" className="table_img status" />
        ) : (
          <img
            src={`${config.IMG_URL}/offersimage/${data?.image}`}
            alt="img"
            className="table_img status"
          />
        ),
    },
    {
      key: "Status",
      text: "Status",
      className: "name",
      align: "left",
      sortable: true,
      cell: (data) => (
        <div>
          <button
            onClick={() => Hideshowfun(data)}
            className={
              data?.Status == true
                ? "blog_statusBtn deactive"
                : "blog_statusBtn active"
            }
          >
            {data?.Status ? "Deactive" : "Active"}
          </button>
        </div>
      ),
    },

    {
      key: "action",
      text: "Edit action",
      className: "action",
      width: 100,
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <Fragment>
            <button
              className="btn btn-primary btn-sm cmn-btn"
              onClick={() => editRecord(record)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-edit mr-0" />
            </button>

            <button
              className="btn btn-primary btn-sm cmn-btn"
              onClick={() => {
                setDeletemodel(true);
                deleteblogs(record);
              }}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-trash mr-0" />
            </button>
          </Fragment>
        );
      },
    },
  ];

  const configs = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Users",
    no_data_text: "No user found!",
    button: {
      excel: true,
      print: true,
      csv: true,
    },
    className: "table",
    language: {
      length_menu: "Show _MENU_",
      filter: "Filter Blogs",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "<<",
        previous: "<",
        next: ">",
        last: ">>",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

  const editRecord = async (data) => {
    push("editOffersTop", { data: data });
  };

  return (
    <div id="dashboard" className="showsidebar">
      <div id="sidebar">
        <Sidebar />
      </div>
      <div className="headandcont">
        <div className="adminheader">
          <AdminHeader />
        </div>
        <div className="cont">
          <h4 className="adminblue my-4">Offers</h4>
          <div className="">
            <Nav
              variant="pills"
              className="navCustom_tabs mb-4"
              // activeKey={activeTab}
              defaultActiveKey="All"
            >
              <Nav.Item className="mb-3 mb-sm-0">
                <Nav.Link
                  eventKey={"All"}
                  onClick={() => {
                    setSelectTab("All");
                  }}
                >
                  <span>{"All"}</span>
                </Nav.Link>
              </Nav.Item>
              {Report.map((item, index) => (
                <>
                  {/* {console.log("itemds", item.value)} */}
                  <Nav.Item className="mb-3 mb-sm-0" key={index}>
                    <Nav.Link
                      eventKey={`tab-${index}`}
                      onClick={() => {
                        setSelectTab((item?.value).toString());
                      }}
                    >
                      <span>{item?.label}</span>
                    </Nav.Link>
                  </Nav.Item>
                </>
              ))}
            </Nav>
          </div>
          <div className="pl-4 text-end mt-3">
            <button
              className="btn btn-theme me-0"
              onClick={() => {
                push("/addOffersTop");
              }}
            >
              Add
            </button>
          </div>

          <div className="tablesec shadowbox px-3 mt-3 br-10 mb-5">
            {/* <h6 className='adminblue pt-4 me-2' ><span className='fa fa-book'></span> View </h6> */}
            {/* <DataTable pagination columns={columns} records={data} config = {config} className={config.className}/> */}
            <DataTable
              striped
              responsive
              config={configs}
              className={configs.className}
              records={blogs}
              columns={columns}
            />
          </div>
        </div>
      </div>
      {/* {Deletemodel && Record &&
                <DeleteModal
                    show={Deletemodel}
                    handleClose={() => setDeletemodel(false)}
                    Deletfunction={() => deleteblogs(Record)}
                    Name={'Offerstop'}

                />

            } */}
    </div>
  );
};

export default TableView;
